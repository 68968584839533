@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .theme_bg_green {
    @apply bg-[#4f7661];
  }
  .theme_light_bg_green{
    @apply bg-[#4f76619e];
  }
  .theme_green {
    @apply text-[#4f7661];
  }

  .center_abs_object {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .header_nav_li {
    @apply flex items-center gap-x-1;
  }

  .header_nav_drop_down {
    @apply text-[20px];
  }
  .head_icon_number{
    @apply text-[10px] absolute top-0 -right-3 bg-black text-white text-center min-w-[70%] rounded-full font-semibold;
  }
  .checkout_input{
    @apply rounded-lg px-3 bg-[#ebeff8] w-full py-2 focus:outline-none;
  }
}