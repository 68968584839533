body{
    font-family: 'Inter', sans-serif;
}
.arial_font{
    font-family: 'Arial', sans-serif;
}
.poppins_font{
    font-family: 'Poppins', sans-serif;
}
.body_dis_overflow ,.scroll_review , .contact_address {
    overflow-y: hidden;
}
.banner_underline {
    position: relative;
    transition: 0.4s all ease;
}

.banner_underline:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000;
    bottom: 0;
    left: 0;
}

.celeb_slide_par .slick-dots li button:before {
    font-size: 10px;
}

.baner_slides .slick-dots li button:before {
    font-size: 9px;
    color: #fff;
}

.baner_slides .slick-dots li.slick-active button:before {
    color: #fff;
}

.celeb_slide_par .slick-dots li,
.baner_slides .slick-dots li {
    width: 5px;
    height: 10px;
}

.celeb_slide_par .slick-dots {
    bottom: 10%;
}
.celeb_slide_par .slick-slider.slick-initialized{
    height: 100%;
}
.baner_slides .slick-dots {
    bottom: 10px;
}
.star_goodness .slick-list{
    display: flex;
}
.star_goodness .slick-track{
    display: flex;
    justify-content: stretch;
}
.star_goodness .slick-track div{
    height: 100%;
}

input[type=checkbox].agree_checkbox {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: 10px;
    height: 10px;
    border: 2px solid #555555;
}
input[type=checkbox].login_checkbox {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: 17px;
    height: 17px;
    background-color: #4f7661;
    border-radius: 3px;
}
input[type=checkbox].update_checkbox{
    position: relative;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: #90a6f2;
}
.ship_method input[type=radio]{
    position: relative;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border:3px solid #90a6f2;
    border-radius: 50%;
}
.acc_det input[type=radio]{
    position: relative;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 5px;
    background-color:#4f7661;
    border:3px solid #4f7661;
}
.acc_det input[type=radio]:checked:after {
    content: "";
    width:14px;
    height: 14px;
    border-radius: 5px;
    background-color: #fb923c;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}
.ship_method input[type=radio]:checked:after {
    content: "";
    width:14px;
    height: 14px;
    border-radius: 50%;
    background-color: #90a6f2;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}
input[type=checkbox].update_checkbox:checked:after {
    content: "";
    display: block;
    width: 4px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%)rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
}
.suggest_slider .slick-slider ,.bts_parent .slick-slider{
    position: static;
}
.scroll_review .ReactModal__Overlay.ReactModal__Overlay--after-open{
    overflow-y: auto;
}
.scroll_review .ReactModal__Content.ReactModal__Content--after-open ,.contact_address  .ReactModal__Content.ReactModal__Content--after-open{
    display: flex;
    align-items: center;
}
input[type=checkbox].agree_checkbox:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: white;
}
input[type=checkbox].login_checkbox:checked:after{
    content: "";
    display: block;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 3px 2px 0;
    transform: translate(-50%, -50%)rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
}
input[type=checkbox].agree_checkbox:checked:after  {
    content: "";
    display: block;
    width: 3px;
    height: 6px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%)rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 10;
    overflow-y:auto;
}

.ReactModal__Content.ReactModal__Content--after-open {
    padding: 0 !important;
    border: 0 !important;
    overflow: unset !important;
}

.youtube_frame {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.scroll_disable {
    overflow: hidden;
}
.cart_popup , .out_popup {
    overflow: hidden;
}
input:focus-visible{
    outline: 0;
}
.cart_popup .ReactModal__Content.ReactModal__Content--after-open {
   inset: unset !important;
   right: 2% !important;
   top:15% !important;
   background-color: #ebeff8 !important;
   overflow-y: hidden !important;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.user_login , .user_register , .ship_address ,.subscribemodel{
    overflow: hidden;
}
.user_login .ReactModal__Content.ReactModal__Content--after-open , 
.out_popup .ReactModal__Content.ReactModal__Content--after-open,
.subscribemodel .ReactModal__Content.ReactModal__Content--after-open{
    inset: unset !important;
    left: 50% !important;
    top:50% !important;
    background-color: #ebeff8 !important;
    overflow-y: hidden !important;
    transform: translate(-50%,-50%);
    width: 45%;
}  
.subscribemodel .ReactModal__Content.ReactModal__Content--after-open{
    overflow: unset !important;
}
.subscribemodel .ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 9999;
}
.out_popup .ReactModal__Content.ReactModal__Content--after-open{
    box-shadow: 2px 5px 21px -10px #000;
}
.ship_address .ReactModal__Content.ReactModal__Content--after-open , .contact_address  .ReactModal__Content.ReactModal__Content--after-open{
    inset: unset !important;
    left: 50% !important;
    top:50% !important;
    background-color: #ebeff8 !important;
    transform: translate(-50%,-50%);
    width: 35%;
}  
.contact_address  .ReactModal__Content.ReactModal__Content--after-open{
    background-color: transparent  !important;
}
.user_register .ReactModal__Content.ReactModal__Content--after-open{
    inset: unset !important;
    left: 50% !important;
    top:50% !important;
    background-color: #ebeff8 !important;
    overflow-y: hidden !important;
    transform: translate(-50%,-50%);
    width: 45%;
}
.header_sub:hover > .prod_nav{
    display: block;
    position: absolute;
    right: -100%;
    top: 0;
    background: white;
    padding: 10px 10px 10px;
    border-radius: 5px;
    width: 100%;
}
.header_sub:hover > .header_insub{
    color: #ffa500;
}
.text-nowrap{
    text-wrap: nowrap;
}
.rating-stars label {
    width: 22px;
    height: 22px;
    background: #ffa500;
    display: inline-flex;
    cursor: pointer;
    margin: 10px 10px 0 0;
    transition: all 1s ease 0s;
    clip-path: polygon(50% 0%, 66% 32%, 100% 38%, 78% 64%, 83% 100%, 50% 83%, 17% 100%, 22% 64%, 0 38%, 34% 32%);
}
.rating-stars label.empty_stars{
    background: rgb(255 238 208 / 90%);
}
.rating-stars label:before {
    width: 100%;
    height: 100%;
    content: "";
    /* background: #ffa500; */
    z-index: -1;
    display: block;
    margin-left: 0;
    margin-top: 0;
    clip-path: polygon(50% 0%, 66% 32%, 100% 38%, 78% 64%, 83% 100%, 50% 83%, 17% 100%, 22% 64%, 0 38%, 34% 32%);
    /* background: linear-gradient(90deg, #ffa500, #ffa500 30% 50%, #184580 50%, 70%, #173a75 100%); */
    background-size: 205% 100%;
    background-position: 0 0;
}
.rating_stars{
    -webkit-appearance: none;
}
.rating-stars label:hover:before {
    transition: all 0.25s ease 0s;
}
.rating-stars input:checked+label~label:hover:before {
    background-position: 0% 0;
}
.rating-stars input:checked+label~label:before {
    background-position: 100% 0;
    transition: all 0.25s ease 0s;
    background: rgb(255 238 208 / 77%);
}
.pay_load{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    background: white;
    z-index: 9999;
}
.circle_image{
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 180px;
    max-height: 180px;
}
.pay_load .wait{
    position: absolute;
    min-width: 400px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    bottom: -50px;
}
.combo_subtitle::before{
    position: absolute;
    content: '';
    top: 8px;
    left: -40px;
    background: #69717e;
    height: 2px;
    width: 35px;
}
.combo_tabs{
    box-shadow: 0px 4px 0px #aaaaac;
}
.tetinermonial_ineer_wraper{
    margin-top: 0;
}
.single_small_car img{
    aspect-ratio: 1/1;
}
select {
    appearance: none; /* Remove default arrow in some browsers */
    -webkit-appearance: none;
    -moz-appearance: none;
}
.term-cont p{
    font-size: 1rem;
}
.term-cont title{
    font-weight: 600;
    font-size: 20px;
}
.term-con p{
    margin-top: 1rem;
}
/* .celeb_slide_par .slick-slider.slick-initialized{
    height: 100%;
    padding-top: 50px;
} */
@media(min-width: 600px) {
    .user_login .ReactModal__Content.ReactModal__Content--after-open{
        width: 600px;
    }
}
@media(max-width: 600px) {
    .user_login .ReactModal__Content.ReactModal__Content--after-open{
        width: 500px;
    }
}
@media(min-width: 1024px) {
    .tetinermonial_ineer_wraper{
        margin-top: 50px;
    }
}
@media(min-width: 1400px) {
    .user_register .ReactModal__Content.ReactModal__Content--after-open{
        width: 30%;
    }
    .container {
        max-width: 1400px;
    }
    .container.profile-container{
        max-width: 1300px;
    }
}
@media(max-width: 1280px) {
    .celeb_slide_par .slick-dots {
        bottom: 5%;
    }

}
@media(max-width: 1200px){
    .term-cont p{
        font-size: 14px;
    }
    .term-cont title{
        font-size: 19px;
    }
}
@media(max-width: 1000px) {
    .contact_address  .ReactModal__Content.ReactModal__Content--after-open{
        width: 50%;
    }
    .bann_img{
        background-position-x: 60% !important;
    }
    /* .tetinermonial_ineer_wrape .testimonial_inner_image{
        width: 100px;
    } */
    .ship_address .ReactModal__Content.ReactModal__Content--after-open {
        width: 45%;
    }
}
@media(max-width: 900px) {
    .user_register .ReactModal__Content.ReactModal__Content--after-open{
        width: 70%;
    }
}
@media(max-width: 800px) {
    
    input[type=checkbox].agree_checkbox {
        width: 12px;
        height: 12px;
    }
    input[type=checkbox].agree_checkbox:checked:after{
        width: 4px;
        height: 7px;
        top: 38%;
    }
}
@media (orientation: landscape) {
    .baner_slides.landscape_banner{
        display: block;
    }
    .baner_slides.portrait_banners{
        display: none;
    }
}
@media(max-width: 740px) {
    .shop_concern_parent a img{
        object-position: 85%;
    }
    .cart_popup .ReactModal__Content.ReactModal__Content--after-open {
        top: 2% !important;
        max-height: 95% !important;
        height: 95% !important;
    }
    .ship_address .ReactModal__Content.ReactModal__Content--after-open {
        width: 60%;
    }
    .term-cont p{
        font-size: 13px;
    }
    .term-cont title{
        font-size: 18px;
    }
}
@media(max-height: 700px) {
    .scroll_review .ReactModal__Content.ReactModal__Content--after-open {
        display: block;
    }
}
@media(max-height: 500px) {
     .contact_address .ReactModal__Content.ReactModal__Content--after-open {
        display: block;
    }
}
@media screen and (orientation: landscape) and (max-width: 900px) {
    .cart_products_parent{
        min-height: 60%;
    }
}
@media screen and (orientation: landscape) and (max-width: 740px) {
   .prod_title{
    width: 25% !important;
   }
}
@media(max-width:1180px) {
    .test_desc{
        font-size: 12px !important;
    }
}
@media(max-width:1024px) {
    .subscribemodel .ReactModal__Content.ReactModal__Content--after-open{
        width: 60%;
    }
}
@media(max-width:740px) {
    .subscribemodel .ReactModal__Content.ReactModal__Content--after-open{
        width: 90%;
    }
}
@media(max-width: 500px) {
    .cart_popup .ReactModal__Content.ReactModal__Content--after-open {
        top: 2% !important;
        max-height: 95% !important;
        height: 95% !important;
    }
    .bann_img{
        background-position-x: 50% !important;
    }
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 95% !important;
    }
    .subscribemodel .ReactModal__Content.ReactModal__Content--after-open{
        width: 90% !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
    .cart_popup .ReactModal__Content.ReactModal__Content--after-open{
        height: 55% !important;
    }
 }