/*html {
    scroll-snap-type:y mandatory;
}*/
.about-container-odd,
.about-container-even
{
    display: flex;
     gap:1rem;
    flex-direction: column;
    min-height: 100%;
}
.head
{
    display: flex;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
    justify-content: center;
}
.title
{
    font-weight: 600;
    font-size: 20px;
  
}
.about-cont {
    padding:2rem 2.5rem;
    box-sizing: border-box;
}

.about-cont p{
    margin-top: 1rem;
    font-size: 1rem;
}
.sub_check{
    position: relative;
    cursor: pointer;
    appearance: none;
    width: 12px;
    height: 12px;
    border: 6px solid #9aacf8b3;
    border-radius: 2px;
}
.sub_check:checked{
    border: none;
    appearance: auto;
}

@media(min-width:   768px)
{
    .head{
        margin-bottom: 0px;
    }
    .about-container-odd{
        flex-direction: row;
        max-width: 80%;
        gap:4rem;
    }
    .about-container-even{
        flex-direction: row-reverse;
        max-width: 80%; 
        gap:4rem;
    }
    .left-cont
    {
        width: 65%;
    }
    .right-cont
    {
        width: 35%;
        padding:3rem 0;
        display: grid;
        align-content: center;
    }
    .img
    {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .about-cont {
     display: grid;
     place-items: center;
     grid-template-rows: auto auto; 
     padding:3rem 2.5rem;
    }
}
@media(max-width: 1200px){
    .head {
        font-size: 23px;
    }
    .title {
        font-size: 19px;
    }
    .about-cont p{
        font-size: 14px;
    }
}
@media(max-width: 740px){
    .head {
        font-size: 22px;
    }
    .title {
        font-size: 18px;
    }
    .about-cont p{
        font-size: 13px;
    }
}