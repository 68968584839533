.term-title {
    display: flex;
    justify-content: center;
    padding: 2rem 0px;
    font-weight: 600;
    font-size: 1.5rem;
   
}
.terms-con
{
padding: 0rem 2.5rem;
}